<script>
import {reactive, toRefs, watch} from 'vue';

export default {
  name: 'DataSwitcher',
  setup() {
    const data = reactive({
      liveData: true,
    });

    watch(_ => data.liveData, (value) => {
      window.eventBus.emit('clearMap');
      window.eventBus.emit('liveData', value);
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <div>
    <el-switch
        v-model="liveData"
        class="mb-2"
        active-text="Live data"
        inactive-text="History data"
    />
  </div>
</template>

<style scoped>

</style>