<script>
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LCircle, LPolyline, LMarker} from "@vue-leaflet/vue-leaflet";
import "leaflet-spline";
import L from "leaflet";
import "leaflet.fullscreen";
import {debounce} from "lodash";

export default {
  name: 'LeafletMap',
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LPolyline,
    LMarker
  },
  data() {
    return {
      liveData: true,
      slidersValue: [],
      zoom: 19,
      map: null,
      userLatLngs: [],
    }
  },
  mounted() {
    window.eventBus.on('liveData', (value) => {
      this.liveData = value;
    });

    window.eventBus.on('clearMap', _ => {
      //vyprazdnit userLatLngs
      this.userLatLngs = [];
      this.slidersValue = [];
    });
  },
  methods: {
    addUserLatLngs(userId, userColor, userName) {
      let userLatLng = {
        user: {
          id: userId,
          color: userColor,
          name: userName
        },
        // polyline: L.polyline([], {
        //   color: userColor,
        //   smoothFactor: 1
        // }).addTo(this.map),
        items: [],
        sortedLatLngs: []
      };
      this.userLatLngs.push(userLatLng);
      return userLatLng;
    },
    mapReady() {
      this.map = this.$refs.map.leafletObject;

      // let OffsetTileLayer = L.TileLayer.extend({
      //   _getTilePos: function (coords) {
      //     let pos = L.TileLayer.prototype._getTilePos.call(this, coords);
      //     return pos.add([-40, -3]);
      //   },
      // });
      // new OffsetTileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   attribution: '©D5T5',
      //   opacity: 1
      // }).addTo(this.map);
      //

      let offsetTileLayerWms = L.TileLayer.WMS.extend({
        _getTilePos: function (coords) {
          let pos = L.TileLayer.WMS.prototype._getTilePos.call(this, coords);
          return pos.add([-40, -3]);
        },
      });

      new offsetTileLayerWms('https://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/service.svc/get?', {
        layers: 'GR_ORTFOTORGB',
      }).addTo(this.map);

      //FULLSCREEN
      L.control.fullscreen().addTo(this.map);

      window.eventBus.on('UdpGpsItem', (data) => {


        let userLatLng = this.userLatLngs.find((userLatLngs) => {
          return userLatLngs.user.id === data.user.id;
        });

        if (userLatLng === undefined) {
          userLatLng = this.addUserLatLngs(data.user.id, data.user.color, data.user.name, []);
        }

        userLatLng.items.push({
          unixtime: data.unixtime,
          latLng: [data.lat, data.lon],
        });

        // pokud ve slidersValue existuje klic data.user.id a jsou liveData tak druha hodnota na aktualni pocet items
        if (this.slidersValue[data.user.id] !== undefined && this.liveData) {
          this.slidersValue[data.user.id][1] = userLatLng.items.length;
        }

        //srovnano podle unixtime a vytazeny pouze latLng a ulozeno do sortedLatLngs
        userLatLng.sortedLatLngs = userLatLng.items.sort((a, b) => {
          return a.unixtime - b.unixtime;
        }).map((item) => {
          return item.latLng;
        });

        // userLatLng.polyline.setLatLngs(userLatLng.sortedLatLngs);

        this.map.fitBounds(userLatLng.sortedLatLngs);
      });

    },
    setSliders: debounce(function (value, id) {
      this.slidersValue[id] = value;
    }, 25)
  },
  computed: {
    userLatLngsSlided() {
      if (this.slidersValue.length === 0) {
        return this.userLatLngs;
      }

      return this.userLatLngs.map((userLatLng) => {
        let sortedLatLngs = userLatLng.sortedLatLngs.slice(this.slidersValue[userLatLng.user.id][0], this.slidersValue[userLatLng.user.id][1]);
        return {
          user: userLatLng.user,
          sortedLatLngs: sortedLatLngs
        }
      });
    }
  }
}
</script>

<template>
  <div style="height:850px; width:1200px" class="mb-1">
    <l-map @ready="mapReady"
           ref="map"
           v-model:zoom="zoom"
           :center="[50.6595989, 14.5390750]">

      <div v-for="(userLatLng, idxx) in userLatLngsSlided" :key="userLatLng.user.id">
        <l-polyline :smooth-factor="1"
                    :lat-lngs="userLatLng.sortedLatLngs"
                    :key="idxx"
                    :color="userLatLng.user.color"/>

        <l-circle v-for="(latLng, idx) in userLatLng.sortedLatLngs"
                  :key="idx"
                  :lat-lng="latLng"
                  :radius="1"
                  fill-color="black"
                  :fill-opacity="1"
                  :color="userLatLng.user.color"/>
      </div>
    </l-map>
  </div>

  <el-slider
      v-for="userLatLng in userLatLngs"
      :key="userLatLng.user.id"
      @input="setSliders($event, userLatLng.user.id)"
      range
      :step="1"
      :min="0"
      :max="userLatLng.items.length"
  ></el-slider>
</template>

<style scoped>
@import '~leaflet.fullscreen/Control.FullScreen.css';
</style>