// CSS
import 'bootstrap/scss/bootstrap.scss';
// import 'animate.css/animate.css';

//Fontawesome PRO
import './Css/fontawesome/scss/fontawesome.scss';
import './Css/fontawesome/scss/light.scss';

//JS
import * as bootstrap from 'bootstrap';

// VUE
import { createApp } from 'vue/dist/vue.esm-bundler';
import {reactive, toRefs} from "vue";

//Element-plus
import ElementPlus from 'element-plus';
import elementCS from 'element-plus/es/locale/lang/cs'
import 'element-plus/dist/index.css';

// mitt
import mitt from 'mitt';
window.eventBus = mitt();

import helpers from './helpers';
window.helpers = helpers;

// COMPONENTS
import Test from "./Components/Test/Test.vue";
import UdpViewer from "./Components/UdpViewer/UdpViewer.vue";
import LeafletMap from "./Components/Map/LeafletMap.vue";
import DataSwitcher from "./Components/Data/DataSwitcher.vue";

// VUE INIT AND MOUNT
export const app = createApp({
	setup() {
		const data = reactive({
		});

		return {
			...toRefs(data)
		}
	}
})
	.component(Test.name, Test)
	.component(UdpViewer.name, UdpViewer)
	.component(LeafletMap.name, LeafletMap)
	.component(DataSwitcher.name, DataSwitcher)
	.use(ElementPlus, { locale: elementCS })
	.mount('#app');