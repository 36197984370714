// noinspection JSUnresolvedVariable

import axios from "axios";

const funcs = {
	string: {
		capitalizeFirstLetter(str) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
		truncateMiddle(str, left = 25, right = 25) {
			return truncateMiddleFunc(str, left, right, '...');
		},
		countWords(str) {
			return str.split(' ')
				.filter(function (n) {
					return n !== ''
				})
				.length;
		},
		countUniqueChars(str, isCaseSensitive = false, ignoreWhitespace = true) {
			let uniq = '';

			if (isCaseSensitive === false) {
				str = str.toLowerCase();
			}

			if (ignoreWhitespace === true) {
				str = str.replace(' ', '');
			}

			for (let x = 0; x < str.length; x++) {
				if (uniq.indexOf(str.charAt(x)) === -1) {
					uniq += str[x];
				}
			}

			return uniq.length;
		}
	},
	file: {
		humanFileSize(bytes, si = true, dp = 1) {
			const thresh = si ? 1000 : 1024;

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}

			const units = si
				? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			const r = 10 ** dp;

			do {
				bytes /= thresh;
				++u;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


			return bytes.toFixed(dp) + ' ' + units[u];
		},
		isFileImage(file) {
			return file && file['type'].split('/')[0] === 'image';
		},
		isFileVideo(file) {
			return file && file['type'].split('/')[0] === 'video';
		},
		isFileAudio(file) {
			return file && file['type'].split('/')[0] === 'audio';
		}
	},
	number: {
		toFixedNumber(num, digits, base = 10) {
			let pow = Math.pow(base, digits);
			return Math.round(num * pow) / pow;
		},
		random(min, max) {
			return Math.floor(Math.random() * ((max) - min)) + min;
		}
	},
	axios: {
		config(headers = {}, onUploadProgress = () => {
		}) {
			return {
				transformResponse: [
					(data, headers) => this.proccessData(data, headers)
				],
				headers: {...{"X-Requested-With": "XMLHttpRequest"}, ...headers},
				onUploadProgress: onUploadProgress
			};
		},
		proccessData(data, headers) {
			let contentType = headers['content-type'];
			if (contentType !== undefined && contentType.includes('application/encrypted')) {
				let iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
				let key256Bits = CryptoJS.enc.Utf8.parse('=/h4r^C0@15$Z8WRJZ3rG3v"@4;BKf1%');
				data = CryptoJS.AES.decrypt(data, key256Bits, {iv: iv}).toString(CryptoJS.enc.Utf8);
			}

			let ret = data;

			if (contentType.includes('application/encrypted') || contentType.includes('application/json')) {
				ret = JSON.parse(data);
			}

			if ('redirect' in ret) {
				window.location = ret.redirect;
			}

			if ('reload' in ret) {
				window.location.reload();
			}

			return ret;
		},
		data(objParams) {
			return new URLSearchParams(objParams).toString()
		}
	},
	filters: {
		firstUpper(value) {
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
		lower(value) {
			return value.toLowerCase();
		},
		truncate(value, length, append = '...') {
			if (value.length <= length) {
				return value;
			}

			let shortedText = value.substring(0, length);
			let lastSpace = shortedText.lastIndexOf(" ");

			if (lastSpace !== -1) {
				shortedText = shortedText.substring(0, lastSpace);
			}

			return shortedText + append;
		},
		distancify(value, lang) {
			let rkm = Number.parseFloat(value).toFixed(2);
			let rmi = (rkm / 1.609).toFixed(2);
			return `${rkm} ${lang.info.km} (${rmi} ${lang.info.mi})`;
		},
		distance(valueInKm, lang, unit, precision = 0) {
			let value = Number.parseFloat(valueInKm).toFixed(precision);
			switch (unit) {
				case 'mi':
					let mil = value / 1.609344;
					return `${numeral(mil).format('0,0.0')} ${lang.info.mi}`;
				case 'km':
				default:
					return `${numeral(value).format('0,0')} ${lang.info.km}`;
			}
		},
		temperature(value, lang, unit) {
			let celsius = Number.parseFloat(value);
			switch (unit) {
				case 'celsius':
					return `${numeral(celsius).format('0,0')}°C`;
				case 'fahrenheit':
				default:
					let fahrenheit = ((celsius * 1.8) + 32);
					return `${numeral(fahrenheit).format('0,0.0')}°F`;
			}
		},
		fluid(value, lang) {
			let val = Number.parseFloat(value);
			return `${numeral(val).format('0,0.0')} ${lang.info.uLiter}`;
		},
		numberWithCommas(value, comma = ',') {
			let processed = value.toString().replace(/ /g, '');
			let number = parseFloat(processed);
			if (isNaN(number)) {
				number = '';
			}
			return number.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, comma);
		}
	},
	browser: {
		location(location) {
			window.location = location;
		},
		reload() {
			window.location.reload();
		},
		timeout(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		imageUrlToBase64(imageUrl) {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.crossOrigin = "Anonymous"; // Umožní načíst obrázek z jiné domény (pokud je to povoleno na serveru)

				img.onload = () => {
					const canvas = document.createElement("canvas");
					canvas.width = img.width;
					canvas.height = img.height;

					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0);

					const dataURL = canvas.toDataURL("image/png"); // nebo jiný formát (image/jpeg, image/gif)
					resolve(dataURL);
				};

				img.onerror = (error) => {
					reject(error); // Pokud se obrázek nepodaří načíst, vrátíme chybu
				};

				img.src = imageUrl;
			});
		}
	},
	validate: {
		email(value) {
			const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			return re.test(String(value).toLowerCase());
		}
	},
	bits: {
		toLangArray(value, langGroup, langPrefix, count = 16) {
			let ret = [];
			let lastBit = 1;
			for (let i = 1; i <= count; i++) {
				let key = langPrefix + lastBit;
				if (value & lastBit && langGroup[key]) {
					ret.push(langGroup[key]);
				}
				lastBit += lastBit;
			}
			return ret;
		}
	},
	array: {
		search(columns, query, item) {
			if (!query) {
				return item;
			}
			query = query.toLowerCase();
			let res = false;

			for (let column of columns) {
				let value = item[column];
				if (res === true || isEmpty(value)) {
					continue;
				}

				value = value.toLowerCase();
				if (value.includes(query)) {
					res = true;
					break;
				}
			}

			return res;
		}
	},
	localStorage: {
		set(key, value, namespace = null) {
			if (namespace !== null) {
				key = namespace + '-' + key;
			}
			localStorage.setItem(key, value);
		},
		setWithExpiry(key, value, ttlms, namespace = null) {
			let now = new Date();

			let item = {
				value: value,
				expiry: now.getTime() + ttlms,
			};
			this.set(key, JSON.stringify(item), namespace);
		},
		get(key, retDefault = null, namespace = null) {
			if (namespace !== null) {
				key = namespace + '-' + key;
			}
			let item = localStorage.getItem(key);
			if (item === null || item === undefined) {
				return retDefault;
			}
			return item;
		},
		getWithExpiry(key, retDefault = null, namespace = null) {
			if (namespace !== null) {
				key = namespace + '-' + key;
			}
			let itemStr = localStorage.getItem(key);
			if (!itemStr) {
				return retDefault;
			}
			let item = JSON.parse(itemStr);

			let now = new Date();
			if (now.getTime() > item.expiry) {
				localStorage.removeItem(key);
				return retDefault;
			}
			return item.value;
		}
	},
	vue: {
		errorCaptured(err, vue, info) {
			let data = {
				action: 'vue-error',
				info: info,
				name: err.name,
				message: err.message,
				stack: err.stack === null || err.stack === undefined ? '' : err.stack,
			};
			axios.post('/xhr/web/log-error', funcs.axios.data(data), funcs.axios.config())
				.then(_ => {})
				.catch(_ => {});
		}
	},
	server: {
		fetchPost(link, data, fullResponse = false, showAlert = true) {
			return new Promise(async (resolve, reject) => {

				try {
					let response = await axios.post(link, helpers.axios.data(data), funcs.axios.config());

					if (response.status === 200) {
						if (fullResponse) {
							resolve(response);
						}
						else {
							if (response.data.error === true) {
								if (showAlert) {
									ElMessageBox.alert(
										response.data.result,
										'Chyba'
									);
								}
								reject(response.data);
							} else {
								resolve(response.data.result);
							}
						}
					}
				}
				catch (e) {
					console.log(e);
					reject(e);
				}
			});
		},
	}
};

export default funcs;